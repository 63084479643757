import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { H2, Paragraph, Span } from '@beelineloans/cx-library/src/components/typography';
import { Button, ButtonOutline } from '@beelineloans/cx-library/src/components/buttons';
import { PortalTemplate } from '@beelineloans/cx-library/src/components/layout/Template';
import { applyEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import SideImage from '@beelineloans/cx-library/src/components/SideImage';

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 0 0 0;
  text-align: center;
`;

const ContinueButton = styled(Button)`
  height: 70px;
  padding: 15px 25px !important;
  font-size: 18px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
`;

const RatesButton = styled(ButtonOutline)`
  margin-top: 21px;
  height: 70px;
  padding: 15px 25px !important;
  font-size: 18px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
`;

const LoanGuide = styled(Paragraph)`
  margin-top: 41px;
  font-size: 18px !important;
`;

const Image = styled(SideImage)`
  top: calc(50vh - 192px);
  bottom: initial;
  margin-top: 0;
  
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
    top: calc(50vh - 290px);
  }
`;

const ChatSpan = styled(Span)`
  text-decoration: underline;
  cursor: pointer;
`;

const Title = styled(H2)`
  line-height: 32px;
  font-size: 24px
`;

const Bottom = ({ isRefi }) => (
  <ButtonsWrap>
    <ContinueButton id="fork-apply" to={applyEndpoint}>Get approved for a lockable rate</ContinueButton>
    <RatesButton id="fork-rates" to={isRefi ? CONST.LINKS.MAIN.CALCULATORS.REFINANCE : CONST.LINKS.MAIN.CALCULATORS.DEFAULT}>Check out our rates</RatesButton>
    <LoanGuide bold>Want to talk to a <ChatSpan bold id="fork-lg">Loan Guide</ChatSpan>?</LoanGuide>
  </ButtonsWrap>
);

Bottom.propTypes = {
  isRefi: PropTypes.bool,
};

Bottom.defaultProps = {
  isRefi: true
};

const ApplicationForm = ({ location }) => {
  const BottomComponent = () => <Bottom isRefi={(location.state || {}).isRefi} />;

  return (
    <PortalTemplate showBannerOnMobile={false} bottom={BottomComponent} showBackButton>
      <SEO
        path="/fork"
        title="Get a home loan from your sofa"
        description="Okay, you’ve hit the start button — so where to from here?"
      />
      <Image />
      <Title bold>Okay, you’ve hit the start button — so where to from here?</Title>
    </PortalTemplate>
  );
};

ApplicationForm.propTypes = {
  location: PropTypes.object,
};

export default ApplicationForm;
